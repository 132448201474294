import React from 'react';
import {HashRouter, Route, Switch, Redirect} from 'react-router-dom';
import {Provider, useDispatch} from 'react-redux';
import Highcharts from 'highcharts';

import './App.scss';
import store from './redux/store';
import * as user from './redux/actions/user';
import {retrieveLoginInfo} from './utils/auth';
import LoadingOverlay from './components/LoadingOverlay';


// setup font family for highcharts
Highcharts.setOptions({
  chart: {
    style: {
      fontFamily: 'Montserrat',
    }
  }
});


// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./Pages/Login'));
const Register = React.lazy(() => import('./Pages/Register'));
const Page404 = React.lazy(() => import('./Pages/Page404'));
const Page500 = React.lazy(() => import('./Pages/Page500'));

const PrivateRoute = ({render: renderComponent, location, ...otherProps}) => {
  const dispatch = useDispatch();
  const loginInfo = retrieveLoginInfo();

  if (loginInfo) {
    const {dcEmail, dcKey, token} = loginInfo;
    dispatch(user.setUserAuthToken(dcEmail, dcKey, token));
  }

  return (
    <Route
      {...otherProps}
      render={props =>
        (
          loginInfo ?
            renderComponent(props) :
            (
              <Redirect to={{pathname: '/login', state: {from: location}}} />
            )
        )
      }
    />
  );
};

const App = () => (
  <Provider store={store}>
    <HashRouter>
      <React.Suspense fallback={<LoadingOverlay />}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
          <Route exact path="/register" name="Register Page" render={props => <Register {...props} />} />
          <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
          <PrivateRoute path="/" name="Home" render={props => <DefaultLayout {...props} />} />
        </Switch>
      </React.Suspense>
    </HashRouter>
  </Provider>
);

export default App;
