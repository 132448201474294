import {postRequest} from './restApi';

const AUTH_REST_API_ROOT_URL = 'https://us-central1-wysetime.cloudfunctions.net';

export const signUpRequest = async (userData) => postRequest(`${AUTH_REST_API_ROOT_URL}/authSignUp`, userData, true);

export const signInRequest = async (email, password) => postRequest(`${AUTH_REST_API_ROOT_URL}/authSignIn`, {email, password}, true);

export const changePasswordRequest = async (password, token) => postRequest(`${AUTH_REST_API_ROOT_URL}/userChangePassword`, {password}, true, token);

export const storeLoginInfo = (dcEmail, dcKey, token) => {
  sessionStorage.setItem('dcEmail', dcEmail);
  sessionStorage.setItem('dcKey', dcKey);
  sessionStorage.setItem('token', token);
};

export const clearLoginInfo = () => sessionStorage.clear();

export const retrieveLoginInfo = () => {
  const dcEmail = sessionStorage.getItem('dcEmail');
  const dcKey = sessionStorage.getItem('dcKey');
  const token = sessionStorage.getItem('token');
  return (dcEmail && dcKey && token) ? {dcEmail, dcKey, token} : null;
};
