import {combineReducers} from 'redux';

import {user} from './user';
import {locations} from './locations';
import {subjects} from './subjects';

export default combineReducers({
  user,
  locations,
  subjects,
});
