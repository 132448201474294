import * as t from '../types';

const initialState = {
  subjectList: [],
};

export const subjects = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case t.SUBJECT_SET_SUBJECTS: {
      const {subjects: subjectList} = payload;
      return {
        ...state,
        subjectList,
      };
    }

    case t.SUBJECT_DELETE_SUBJECTS: {
      const {visitorId} = payload;
      const subjectList = state.subjectList.filter((subject) => subject.visitorID !== visitorId);
      return {
        ...state,
        subjectList,
      };
    }

    case t.SUBJECT_CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
};
