import * as t from '../types';

const initialState = {
  locationList: [],
  selectedCamera: null,
};

export const locations = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case t.LOCATIONS_SET_LOCATIONS: {
      const {locations: locationList} = payload;
      return {
        ...state,
        locationList,
      };
    }

    case t.LOCATIONS_DELETE_LOCATION: {
      const {locationId} = payload;
      const locationList = state.locationList.filter((location) => location.partnerLocation !== locationId);
      return {
        ...state,
        locationList,
      };
    }

    case t.LOCATIONS_SET_SELECTED_CAMERA: {
      const {camera: selectedCamera} = payload;
      return {
        ...state,
        selectedCamera
      };
    }

    case t.LOCATIONS_CLEAR: {
      return initialState;
    }

    default:
      return state;
  }
};
