// User action type
export const USER_SET_AUTH_TOKEN = 'user_set_auth_token';
export const USER_CLEAR_AUTH_TOKEN = 'user_clear_auth_token';

export const LOCATIONS_SET_LOCATIONS = 'locations_set_locations';
export const LOCATIONS_DELETE_LOCATION = 'locations_delete_location';
export const LOCATIONS_SET_SELECTED_CAMERA = 'locations_set_selected_camera';
export const LOCATIONS_CLEAR = 'locations_clear';

export const SUBJECT_SET_SUBJECTS = 'subjects_set_subjects';
export const SUBJECT_DELETE_SUBJECTS = 'subjects_delete_subjects';
export const SUBJECT_CLEAR = 'subjects_clear';
