export const postRequest = (url, data, isFirebase, token) => {
  const headers = {
  };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const reqData = isFirebase ? new URLSearchParams() : new FormData();
  Object.keys(data).forEach(dataKey => reqData.append(dataKey, data[dataKey]));

  const response = fetch(url, {
    method: 'POST',
    body: reqData,
    headers,
  });

  return response;
};