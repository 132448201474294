import React from 'react';

import LoadingSpinner from './LoadingSpinner';

const LoadingOverlay = () => (
  <div className="wra-loading-overlay_background">
    <LoadingSpinner />
  </div>
);

export default LoadingOverlay;
